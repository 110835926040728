import React from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

export const Loading = ({ size, color }) => {

  return (
    <SpinnerContainer>
      <CircularProgress
        color={color}
        size="lg"
        value={size}
        style={{width: `${size}px`, height: `${size}px`}}
      />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
// import Iconify from '../components/iconify';
import { RegisterForm } from '../../sections/auth';
import PageTitle from '../../components/page-title';

export default function RegisterPage() {
  return (
    <>
      <PageTitle title={'Sign up'} />

      <Typography variant="h4" gutterBottom>Sign up</Typography>

      <Typography variant="body2" sx={{ mb: 5 }}>
        Already have an account? {''} <NavLink to="/auth/login">Log in</NavLink>
      </Typography>

      {/* <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined">
              <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
            </Button>

            <Button fullWidth size="large" color="inherit" variant="outlined">
              <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
            </Button>

            <Button fullWidth size="large" color="inherit" variant="outlined">
              <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
            </Button>
          </Stack>
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              OR
            </Typography>
          </Divider> */}

      <RegisterForm />
    </>
  );
}

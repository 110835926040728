import Axios from 'axios';
import { ApiError } from './ApiError';
import { readToken } from '../services/localStorage.service';

export const httpApi = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Accept: 'application/json' };
  const accessToken = readToken();
  if (accessToken !== '') config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
  return config;
});

httpApi.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 403) window.location.href = '/auth';
  throw new ApiError(error.response?.data?.message || error.message, error.response?.data);
});

import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  entities: [],
  totalCount: 0,
  loading: false,
  smsForEdit: undefined,
  error: null,
};

export const findAll = createAction('sms/findAll', (payload) => {
  return { payload };
});

export const sendSms = createAction('sms/sendSms', (payload) => {
  return { payload };
});

export const smsSlice = createSlice({
  name: 'sms',
  initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    smsFetched: (state, action) => {
      state.loading = false;
      state.smsForEdit = action.payload.smsForEdit;
      state.error = null;
    },
    smsesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    smsSent: (state, action) => {
      state.loading = false;
      state.error = null;
      const app = { ...action.payload, id: ((state.entities?.length || 0) + 1) };
      state.entities.push(app);
    },
    smsUpdated: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.payload._id) {
          return action.payload;
        }
        return entity;
      });
    },
    smsDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter((el) => el._id !== action.payload._id);
    },
    smsesDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    smsesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, active } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.active = active;
        }
        return entity;
      });
    }
  },
});

export default smsSlice.reducer;
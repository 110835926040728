const appPermissions = {
    permissions: [
        {
            id: "SMS_MANAGER_1",
            name: 'accessToUsers',
            level: 1,
            title: 'Users & Roles'
        },
        {
            id: "SMS_MANAGER_1a",
            name: 'accessToUsersManage',
            level: 2,
            parentId: "SMS_MANAGER_1",
            title: 'Manage users, roles & permissions'
        },
        {
            id: "SMS_MANAGER_6",
            name: 'accessToSettings',
            level: 1,
            title: 'Settings'
        },
        {
            id: "SMS_MANAGER_6a",
            name: 'accessToSettingsManage',
            level: 2,
            parentId: "SMS_MANAGER_6",
            title: 'Manage system settings'
        },
    ]
};

export { appPermissions }
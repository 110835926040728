import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  entities: [],
  totalCount: 0,
  loading: false,
  userForEdit: undefined,
  error: null,
};

export const findAll = createAction('users/findAll', (payload) => {
  return { payload };
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    userFetched: (state, action) => {
      state.loading = false;
      state.userForEdit = action.payload.userForEdit;
      state.error = null;
    },
    usersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    userUpdated: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.payload._id) {
          const user = { ...action.payload };
          user.id = entity.id;
          user.apps = entity.apps;
          return user;
        }
        return entity;
      });
    },
    userDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter((el) => el._id !== action.payload._id);
    },
    usersDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    usersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, active } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.active = active;
        }
        return entity;
      });
    }
  },
});

export default usersSlice.reducer;
import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  entities: [],
  totalCount: 0,
  loading: false,
  appForEdit: undefined,
  error: null,
};

export const findAll = createAction('application/findAll', (payload) => {
  return { payload };
});

export const createApp = createAction('application/createApp', (payload) => {
  return { payload };
});

export const applicationSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    appFetched: (state, action) => {
      state.loading = false;
      state.appForEdit = action.payload.appForEdit;
      state.error = null;
    },
    appsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    appCreated: (state, action) => {
      state.loading = false;
      state.error = null;
      const app = { ...action.payload, id: ((state.entities?.length || 0) + 1) };
      state.entities.push(app);
    },
    appUpdated: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.payload._id) {
          return action.payload;
        }
        return entity;
      });
    },
    appDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter((el) => el._id !== action.payload._id);
    },
    appsDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    appsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, active } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.active = active;
        }
        return entity;
      });
    }
  },
});

export default applicationSlice.reducer;
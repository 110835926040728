import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doResendVerification } from '../../store/slices/authSlice';
import { notificationController } from '../../controllers/notificationController';
import { Loading } from '../../components/Loading';
import { verifyEmail } from '../../api/auth.api';

const verifyEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
});

const initialValues = {
  email: '',
};

export default function VerifyEmailPageForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { token } = useParams();
  const verifying = !(token === undefined);
  const [isRequested, setIsRequested] = useState(false);

  const displayIntro = () => {
    return !verifying ? 'Use email you signed up with to continue.' : 'Verifying your email, please wait...';
  };

  const verifyWithToken = verifying
    ? () => {
      verifyEmail(token || '')
        .then((resp) => {
          if (resp?.status === 1) {
            notificationController.success({
              title: 'Success',
              message: 'You successfully verified your email, log in to continue.',
            });
            navigate('/auth/login');
          } else if (resp?.status === 3) {
            notificationController.error({
              title: 'Oops!',
              message: 'Your verification code has expired. Please try verifying your email again.',
            });
            navigate('/auth/verify-email');
          } else {
            notificationController.error({
              title: 'Oops!',
              message: "Sorry, we're unable to verify your email. Please try again.",
            });
            navigate('/auth/verify-email');
          }
        })
        .catch((err) => {
          notificationController.error({
            title: 'Oops!',
            message: "Sorry, we're unable to verify your email. Please try again.",
          });
          navigate('/auth/login');
        });
    }
    : () => { };

  useEffect(() => verifyWithToken(), []);

  const formik = useFormik({
    initialValues,
    validationSchema: verifyEmailSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(doResendVerification(values.email))
        .unwrap()
        .then(() => {
          notificationController.info({
            title: 'Verification email sent',
            message: `We've sent you a verification email. Please check your inbox & follow the instructions.`,
          });
          navigate('/auth/login');
        })
        .catch((err) => {
          setIsRequested(false);
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    },
  });

  return (
    <>
      {isRequested && <Navigate to="/auth/login" />}
      {!isRequested && (<form
        className=''
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Stack spacing={3} sx={{ mb: 2 }}>
          <Typography>{displayIntro()}</Typography>
          {verifying && (
            <div style={{ width: '40px', height: '80px' }}>
              <Loading size={40} color="primary" />
            </div>
          )}
          {!verifying && (<TextField
            {...formik.getFieldProps('email')}
            name="email"
            type="email"
            label="Email address"
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.errors.email}
          />)}
        </Stack>

        {!verifying && (<LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained">Resend email</LoadingButton>)}
      </form>)}
    </>
  );
}

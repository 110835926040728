import { Typography } from '@mui/material';
import { VerifyEmailPageForm } from '../../sections/auth';
import PageTitle from '../../components/page-title';


export default function VerifyEmailPage() {
  return (
    <>
      <PageTitle title={'Verify email'} />

      <Typography variant="h4" gutterBottom>Verify email</Typography>

      <VerifyEmailPageForm />
    </>
  );
}

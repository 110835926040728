import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const options = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
  // autoClose: 10000,
  pauseOnHover: true,
};

// https://fkhadra.github.io/react-toastify/api/toast
const notification = (type, config) => {
  // toast.dismiss();
  const _options = { ...options };
  let component = config?.message;
  if (config?.duration != null) _options.autoClose = config?.duration;
  if (config?.title != null) component = <><p style={{ margin: 0 }}><strong>{config?.title}</strong></p><span>{config?.message}</span></>
  _options.type = type;
  return toast(component, _options);
}

const successNotification = (config) => {
  return notification(toast.TYPE.SUCCESS, config);
}

const infoNotification = (config) => {
  return notification(toast.TYPE.INFO, config);
}

const warningNotification = (config) => {
  return notification(toast.TYPE.WARNING, config);
}

const errorNotification = (config) => {
  return notification(toast.TYPE.ERROR, config);
}

/**
  @param {string} title - Title of the notification (Optional)
  @param {string} message - Message to show in the notification
  @param {int} duration - Time in milliseconds (Optional, default is 15000)
*/
export const notificationController = {
  success: successNotification,
  info: infoNotification,
  warning: warningNotification,
  error: errorNotification,
};

import * as request from '../../api/roles.api';

const { rolesSlice } = require("../slices/rolesSlice");

const { actions } = rolesSlice;

export const fetchRoles = (queryParams) => (dispatch) => {
    dispatch(actions.startCall());
    return request
        .getAllRoles(queryParams)
        .then(response => {
            const { data: { totalCount, entities } } = response;
            dispatch(actions.rolesFetched({ totalCount, entities }));
        })
        .catch((err) => {
            const error = "A critial error occurred, please try reloading the page.";
            dispatch(actions.catchError({ error }));
        });
};

export const createRole = (payload) => (dispatch) => {
    dispatch(actions.startCall());
    return request
        .createRole(payload)
        .then(response => {
            const { data } = response;
            // console.log(data);
        })
        .catch((err) => {
            const error = "A critial error occurred, please try again.";
            dispatch(actions.catchError({ error }));
        });
};

export const updateRole = (id, payload) => (dispatch) => {
    dispatch(actions.startCall());
    return request
        .updateRole(id, payload)
        .then(response => {
            const { data } = response;
            // console.log(data);
            // dispatch(actions.roleUpdated({ data }));
        })
        .catch((err) => {
            const error = "A critial error occurred, please try updating again.";
            dispatch(actions.catchError({ error }));
        });
};

export const deleteRole = (id) => (dispatch) => {
    dispatch(actions.startCall());
    return request
        .deleteRole(id)
        .then(response => {
            const { data } = response;
            dispatch(actions.roleDeleted({ data }));
        })
        .catch((err) => {
            const error = "A critial error occurred, please try updating again.";
            dispatch(actions.catchError({ error }));
        });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  login,
  logout,
  signUp,
  resetPassword,
  verifyEmail,
  setNewPassword,
  resendVerificationEmail,
} from '../../api/auth.api';
import { setUser } from './userSlice';
import { deleteToken, deleteUser, readToken } from '../../services/localStorage.service';

const initialState = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload, { dispatch }) =>
  login(loginPayload).then((res) => {
    return res;
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk('auth/doResetPassword',
  async (resetPassPayload) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode', async (token) =>
  verifyEmail(token),
);

export const doResendVerification = createAsyncThunk(
  'auth/doResendVerification', async (email) =>
  resendVerificationEmail(email),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', async (payload, { dispatch }) => {
  await logout();
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
      state.user = undefined;
    });
  },
});

export default authSlice.reducer;

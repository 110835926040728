import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import LoginPage from '../pages/Authentication/LoginPage';
import RegisterPage from '../pages/Authentication/RegisterPage';
import ForgotPasswordPassword from '../pages/Authentication/ForgotPasswordPassword';
import VerifyEmailPage from '../pages/Authentication/VerifyEmailPage';

import SimpleLayout from '../layouts/simple';
import { withLoading } from '../hocs/withLoading.hoc';
import RequireAuth from './RequireAuth';

import Page404 from '../pages/Page404';
import { userPermissions } from '../utils/userPermissions';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('../layouts/AuthLayout/AuthLayout'));
const MainLayout = React.lazy(() => import('../layouts/main'));

const DashboardPage = React.lazy(() => import('../pages/DashboardAppPage'));
const ApplicationsPage = React.lazy(() => import('../pages/Applications/ApplicationsPage'));
const ViewApplicationPage = React.lazy(() => import('../pages/Applications/ViewApplicationPage'));
const EditApplicationPage = React.lazy(() => import('../pages/Applications/EditApplicationPage'));
const ViewApiKeyPage = React.lazy(() => import('../pages/Applications/ViewApiKeyPage'));
const ViewSmsPage = React.lazy(() => import('../pages/Sms/ViewSmsPage'));
const ViewUsersPage = React.lazy(() => import('../pages/Users/ViewUsersPage'));
const ViewRolesPage = React.lazy(() => import('../pages/Roles/ViewRolesPage'));
const ViewSettingsPage = React.lazy(() => import('../pages/Settings/ViewSettingsPage'));
const ViewProfilePage = React.lazy(() => import('../pages/Profile/ViewProfilePage'));

const Logout = React.lazy(() => import('./Logout'));

const Dashboard = withLoading(DashboardPage);
const Applications = withLoading(ApplicationsPage);
const EditApplication = withLoading(EditApplicationPage);
const ViewApplication = withLoading(ViewApplicationPage);
const ViewApiKey = withLoading(ViewApiKeyPage);
const ViewSms = withLoading(ViewSmsPage);
const ViewUsers = withLoading(ViewUsersPage);
const ViewRoles = withLoading(ViewRolesPage);
const ViewSettings = withLoading(ViewSettingsPage);
const ViewProfile = withLoading(ViewProfilePage);

const Page404Page = withLoading(Page404);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const MainLayoutFallback = withLoading(MainLayout);

export const AppRouter = () => {
  const user = useSelector((state) => state.user.user);
  const permissions = userPermissions(user?.permissions || []);

  const protectedLayout = (
    <RequireAuth>
        <MainLayoutFallback />
    </RequireAuth>
  );

  return (
      <Routes>
        <Route path="/" element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path="apps" element={<Outlet />} >
            <Route index element={<Applications />} />
            <Route path="view" exact element={<ViewApplication />} />
            <Route path="edit" exact element={<EditApplication />} />
            <Route path="new" exact element={<EditApplication />} />
            <Route path="key" exact element={<ViewApiKey />} />
          </Route>
          <Route path="sms" element={<Outlet />} >
            <Route index element={<ViewSms />} />
          </Route>

          {/* Manage users */}
          {permissions.includes('accessToUsersManage') && 
          <Route path="users" element={<Outlet />} >
            <Route index element={<ViewUsers />} />
            <Route path="sms" element={<ViewSms isUser />} />
            <Route path="apps" element={<Applications isUser />} />
          </Route>}

          {/* Manage roles */}
          {permissions.includes('accessToUsersManage') && 
          <Route path="roles" element={<ViewRoles />} />}

          {/* Manage setting */}
          {permissions.includes('accessToSettingsManage') && 
          <Route path="settings" element={<ViewSettings />} />}

          {/* Profile */}
          <Route path="profile" element={<ViewProfile />} />

        </Route>

        {/* Authentication */}
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<RegisterPage />} />
          <Route path="forgot" element={<ForgotPasswordPassword />} />
          <Route path="verify-email" element={<VerifyEmailPage />} />
          <Route path="verify/:token" element={<VerifyEmailPage />} />
          <Route path="*" element={<Navigate to="/auth/login" />} />
          <Route path="" element={<Navigate to="/auth/login" />} />
        </Route>

        {/* Error */}
        <Route path="/error" element={<SimpleLayout />} >
          <Route path="404" element={<Page404Page />} />
          <Route path="*" element={<Navigate to="/error/404" />} />
          <Route path="" element={<Navigate to="/error/404" />} />
        </Route>

        {/* Logout */}
        <Route path="/logout" element={<LogoutFallback />} />

        {/* Default */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Routes>
  );
};

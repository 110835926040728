import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
// import Iconify from '../components/iconify';
import { LoginForm } from '../../sections/auth';
import PageTitle from '../../components/page-title';

export default function LoginPage() {
  return (
    <>
      <PageTitle title={'Log in'} />

      <Typography variant="h4" gutterBottom>Log in</Typography>

      <Typography variant="body2" sx={{ mb: 5 }}>
        Don't have an account? {''} <NavLink to="/auth/signup">Get started</NavLink>
      </Typography>

      {/* <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined">
              <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
            </Button>

            <Button fullWidth size="large" color="inherit" variant="outlined">
              <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
            </Button>

            <Button fullWidth size="large" color="inherit" variant="outlined">
              <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
            </Button>
          </Stack>
          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              OR
            </Typography>
          </Divider> */}

      <LoginForm />
    </>
  );
}

import { httpApi } from './http.api';

export const login = (loginPayload) =>
  httpApi.post('/v1/users/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData) =>
  httpApi.post('/v1/users/register', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload) =>
  httpApi.post('/v1/users/forgot-password', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifyEmail = (token) =>
  httpApi.post('/v1/users/verify-email', { token }).then(({ data }) => data);

export const resendVerificationEmail = (email) =>
  httpApi.post('/v1/users/resend-verification', { email }).then(({ data }) => data);

export const setNewPassword = (newPasswordData) =>
  httpApi.post('/v1/users/set-password', { ...newPasswordData }).then(({ data }) => data);

export const logout = () =>
  httpApi.post('/v1/users/logout').then(({ data }) => data);
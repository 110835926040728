import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { AppRouter } from './router/AppRouter';
import * as actions from './store/actions/rolesActions'

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();

  return (
    <ThemeProvider>
      <ToastContainer />
      <ScrollToTop />
      <StyledChart />
      <AppRouter />
    </ThemeProvider>
  );
}

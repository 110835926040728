import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  entities: [],
  totalCount: 0,
  loading: false,
  roleForEdit: undefined,
  error: null,
};

export const findAll = createAction('roles/findAll', (payload) => {
  return { payload };
});

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    roleFetched: (state, action) => {
      state.loading = false;
      state.roleForEdit = action.payload.roleForEdit;
      state.error = null;
    },
    rolesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    roleUpdated: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.map((entity) => {
        if (entity._id === action.data._id) {
          return action.data;
        }
        return entity;
      });
    },
    roleDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter((el) => el._id !== action.payload.id);
    },
    rolesDeleted: (state, action) => {
      state.error = null;
      state.loading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    rolesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, active } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.active = active;
        }
        return entity;
      });
    }
  },
});

export default rolesSlice.reducer;
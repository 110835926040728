export const persistToken = (token) => localStorage.setItem('accessToken', token);

export const readToken = () => {
  return localStorage.getItem('accessToken') || '';
};

export const persistUser = (user) => localStorage.setItem('user', JSON.stringify(user));

export const readUser = () => {
  const userStr = localStorage.getItem('user');
  return JSON.parse(userStr);
};

export const deleteToken = () => localStorage.removeItem('accessToken');
export const deleteUser = () => localStorage.removeItem('user');

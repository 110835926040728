import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------


const PageTitle = (({ title }) => (
  <Helmet>
    <title> {title} | SMS Manager </title>
  </Helmet>
));

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;

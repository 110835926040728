import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import authReducer from './authSlice';
import applicationReducer from './applicationSlice';
import smsReducer from './smsSlice';
import usersReducer from './usersSlice';
import rolesReducer from './rolesSlice';
import settingsReducer from './settingsSlice';

export const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  apps: applicationReducer,
  sms: smsReducer,
  users: usersReducer,
  roles: rolesReducer,
  settings: settingsReducer,
});

import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Iconify from '../../components/iconify';
import { doLogin } from '../../store/slices/authSlice';
import { notificationController } from '../../controllers/notificationController';
import { persistToken } from '../../services/localStorage.service';
import { setUser } from '../../store/slices/userSlice';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(doLogin(values))
        .unwrap()
        .then((res) => {
          const user = res?.data?.user;
          const token = res?.data?.access_token;
          if (res?.status === 1) {
            notificationController.success({
              title: 'Success',
              message: `Hey ${user?.firstName}, you successfully logged in.`,
              duration: 3000,
            });
            dispatch(setUser(user));
            persistToken(token);
            navigate('/', { replace: true });
          } else if (res?.status === 6) {
            notificationController.error({
              title: 'Unverified account',
              message: `Your account is not verified. We've already sent a verification email to your email, verify to continue.`,
            });
            setLoading(false);
          } else {
            notificationController.error({
              title: 'Incorrect log in',
              message: 'Login credentials are incorrect, please try again.',
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    },
  });

  return (
    <form
      className=''
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <Stack spacing={3}>
        <TextField
          {...formik.getFieldProps('email')}
          name="email"
          type="email"
          label="Email address"
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.errors.email}
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          {...formik.getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.errors.password}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
        <NavLink to="/auth/forgot">
          Forgot password?
        </NavLink>
      </Stack>

      <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained">Log in</LoadingButton>
    </form>
  );
}

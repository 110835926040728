import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import { doSignUp } from '../../store/slices/authSlice';
import { notificationController } from '../../controllers/notificationController';

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val) => (val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
    }),
  acceptTerms: Yup.bool().oneOf([true], 'You must accept the terms & conditions'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
};

export default function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(doSignUp(values))
        .unwrap()
        .then(() => {
          notificationController.success({
            title: `Signed up successfully`,
            message: `A verification email has been sent to ${values.email}, verify to continue.`,
            duration: 12000,
          });
          navigate('/auth/login');
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    },
  });

  return (
    <form
      className=''
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <Stack spacing={3}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          sx={{ width: '100%' }}
          spacing={{ xs: 3, sm: 3, md: 0 }}
        >
          <TextField
            name="firstName"
            label="First name"
            type="text"
            {...formik.getFieldProps('firstName')}
            error={formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            name="lastName"
            label="Last name"
            type="text"
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Stack>
        <TextField
          name="email"
          label="Email address"
          type="email"
          {...formik.getFieldProps('email')}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          {...formik.getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.password && !!formik.errors.password}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          name="confirmPassword"
          label="Confirm password"
          type={showConfirmPassword ? 'text' : 'password'}
          {...formik.getFieldProps('confirmPassword')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="start" sx={{ my: 2 }}>
        <FormControl
          error={formik.touched.acceptTerms && !!formik.errors.acceptTerms}
        >
          <FormControlLabel
            label="I agree to the terms & conditions"
            control={
              <Checkbox
                name="acceptTerms"
                {...formik.getFieldProps('acceptTerms')}
                onChange={(e) => formik.setFieldValue('acceptTerms', e.target.checked)}
                checked={formik.values.acceptTerms}
              />
            }
          />
          <FormHelperText>{formik.touched.acceptTerms && formik.errors.acceptTerms}</FormHelperText>
        </FormControl>
      </Stack>

      <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained">
        Sign up
      </LoadingButton>
    </form>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { errorLoggingMiddleware } from './middlewares/errorLogging.middleware';
import { rootReducer } from './slices';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(errorLoggingMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
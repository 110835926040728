import { httpApi } from './http.api';

export const getAllRoles = (queryParams) =>
  httpApi.post('/v1/roles/find', { queryParams }).then(({ data }) => data);

export const createRole = (payload) =>
  httpApi.post('/v1/roles/create', payload).then(({ data }) => data);

export const updateRole = (id, payload) =>
  httpApi.put(`/v1/roles/update/${id}`, payload).then(({ data }) => data);

export const deleteRole = (id) =>
  httpApi.delete(`/v1/roles/delete/single/${id}`).then(({ data }) => data);

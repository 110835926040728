import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: {},
  loading: false,
  error: null,
};

// export const all = createAction('settings/all', (payload) => {
//   return { payload };
// });

// export const update = createAction('settings/update', (payload) => {
//   return { payload };
// });

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      state.loading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    settingsFetched: (state, action) => {
      state.loading = false;
      state.error = null;
      state.settings = action.payload;
    },
  },
});

export default settingsSlice.reducer;
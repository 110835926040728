import { isRejectedWithValue } from '@reduxjs/toolkit';
import { notificationController } from '../../controllers/notificationController';

/**
 * Log a warning and show a toast!
 */
export const errorLoggingMiddleware = () => (next) => (action) => {
  // console.log(action.payload);
  if (isRejectedWithValue(action) || action.payload?.error) notificationController.error({ title: 'Oops!', message: action.payload?.error, duration: 10000, });
  return next(action);
};
